.loader {
    position: absolute;
    display: flex;
    top: 0px;
    left: 0px;
    z-index: 1;

    height: 100%;
    width: 100%;

    align-items: center;
    text-align: center;
    justify-content: center;

    background: #000A;
    p {
        position: absolute;
        top: 65%
    }
    .MuiCircularProgress-root {
        margin: auto;
        color: white;
    }
}