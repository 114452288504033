// modal popup for browse page
.cards-modal {
    max-width: 425px;
    max-height: 90vh;
    padding-bottom: 20px;

    overflow-y: scroll !important;

    .nav-button {
        position: absolute;
        font-size: 18pt;
        padding: 5px;
        background: black;
        color: white;
        top: 15px;

        &:focus{ background: rgb(49, 49, 49)};
        &.close-button {left: 15px};
        &.next-button {right: 25px};
        &.prev-button {right: 65px};
    }

    .cardBorder {
        padding: 8px;
        background: linear-gradient(90deg, white, silver);

        img {
            width: 100%;
            border-radius: 10px;
        }
    }
    
    p {
        font-size: 10pt;
        padding: 10px 20px;
        &.italic {font-style: italic};
        &.title {
            color: grey;
            font-size: 14pt;
            &.Life {color: rgb(192, 136, 14)};
        };
    }
}

@media only screen and (max-width: 900px) {

    .cards-modal {
        max-width: 350px;
    }
}