.journalGridList {
  width: 500;
  padding: 0px;
}

.journalIcon {
  color: rgba(255, 255, 255, 0.54);
}

.journal-month {
  h3 {
    background-color: white;
    margin: 0;
    padding: 0;
  }

  .MuiGridListTile-tile {
    outline: 2px solid rgb(3, 21, 46);
  }
}

.journal-modal {
  .text, h2, h3 {
    margin: 10px 10px;
  }
  
  .info {
    margin: 10px 10px;
    font-size: 8pt;
  }

  width: 700px;

  max-height: 90vh;
  max-width: 90vw;

  padding: 10px 5px;

  overflow-y: scroll !important;

  background: white;
  border-radius: 5px;
  margin: 20px auto;
  
}