.sidebar {
    button {
        position: absolute;
        min-width: 30px !important;
        top: 10px;
        left: 10px;
        color: rgb(255, 255, 255);
        background-color: #203884;
        // background-color: #61b926;
        height: 60px;
        width: 60px;
        border-radius: 50%;
    }

    .homebuttons {
            position: absolute;
            min-width: 30px !important;
            top: 10px;
            right: 10px;
            color: rgb(255, 255, 255) !important;
            background-color: #203884;
            // background-color: #61b926;
            height: 60px;
            width: 60px;
            border-radius: 50%;
        }

    .MuiIcon-root {
        overflow: inherit !important;
        width: inherit !important;
        height: inherit !important;

        img {
            margin: auto;
            height: 40px;
            width: 40px;
            margin-top: 3px;
        }
    }

    a {
        color: black !important;
    }
}