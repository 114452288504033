@import './css/Navigation.scss';
@import './css/Readings.scss';
@import './css/Browse.scss';
@import './css/cardModal.scss';
@import './css/Journal.scss';
@import './css/cardField.scss';
@import './css/JournalEntry.scss';
@import './css/userModals.scss';

html, body {
	//height: 100%;
  min-height: 100%;
  font-family: century-gothic, sans-serif;
	//overflow: hidden;
}

html {
  //background-image: url('./images/aerialbeach2.jpg');
  background-color: white;
	background-repeat: no-repeat;
  background-size: cover;
	background-position: center center;
	position: relative;
  //height: 100%;
  background-image: url('./images/background.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

html:after {
  content: ' ';
    position: absolute;
    z-index: -1;
    left: 0; right: 0;
    top: 0; bottom: 0;
    background: rgba(255,255,255,0.8);
}

p {
  margin: 0px;
  font-size: 16px;
}

// @media screen and (max-width: 640px) {

// 	html {
// 		background-position: center center;
// 		background-size: fill;
// 	}

// 	img {
// 		margin-top: 20px;
// 		width: 96vw;
// 		z-index: -1;
// 	}
// }

.paragraph {
  img {
      width: 200px;
      max-width: 500px;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

  h2{
    text-align: center;
    color: #1c56a6;
  }

  h3 {
    margin: 20px;
    text-align: center;
  }

  p{
    justify-content: center;
    margin: 20px;
  }
}

.paragraph-centred {
  img {
      width: 90%;
      max-width: 500px;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

  h3 {
    margin: 20px;
    text-align: center;
  }

  h2{
    text-align: center;
    color: #1c56a6;
  }

  p{
    text-align: center;
    margin: 20px;
  }
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-family: 'century-gothic', sans-serif;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url('./images/background.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

// .App2 {
//   //text-align: center;
//   margin-top: 0;
//   min-height: 100vh;
//   //display: flex;
//   //flex-direction: column;
//   //align-items: center;
//   /* justify-content: center; */
//   font-family: 'century-gothic', sans-serif;
//   font-size: calc(10px + 2vmin);
//   color: white;
//   background-image: url('./images/background.jpg');
//   -webkit-background-size: cover;
//   -moz-background-size: cover;
//   -o-background-size: cover;
//   background-size: cover;
//   background-position: center;
// }

.App-logo {
  height: 20vh;
  max-height: auto;
  max-width: 100px;
}

.App-title {
  width: 25vw;
}

.App-header { 
  margin: auto;
  // margin-top: 10px;
  // margin-bottom: 10px;
  // min-height: 100vh;
  width: 95vw;
  max-width: 700px;
  background-color: rgba(255,255,255,0.9);
  border-radius: 30px;
  border-width: 22px;
  border-color: rgba(#000000, 1.0);

  color: black;

  .tonireilly-logo {
    img{
      width: 50px;
      height: auto;
    }
  }

  .guidance-cards-logo {
    img{
      width: 80%;
      max-width: 300px;
      height: auto;
    }

    p {
      color: #1c56a6;
    }
  }

  .darkbox {
    background-color: rgba($color: #000000, $alpha: 0.3);
    border-radius: 10px;
    padding: 8px;
    //margin-top: 10px;
  }

  img {
    margin-top: 10px;
  }
}

.App-link {
  color: #09d3ac;
}

.title {
  margin-top: 10px;
  p {
    font-size: 34px;
    background: -webkit-linear-gradient(180deg, rgb(255, 255, 255), rgb(118, 169, 245), rgb(255, 255, 255), rgb(117, 164, 235), rgb(255, 255, 255));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.carousel {
  color: black;
  width: 80%;
  padding: 40px;
  margin: auto;

  &.white {
    background-color: white;
    border-radius: 10px;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #4669da;
  }
}

.carousel-container {
  max-width: 600px;
  border-radius: 10px;
  background: white;
}

.carousel-image {
    
  img {
    height: 45vh;
    width: auto;
    margin: auto;
  }
    
  }

.blue-background {
  background-color: #203884;
  
  h1 {
    text-align: center;
    font-family: 'century-gothic', sans-serif;
    font-size: 32px !important;
    background-color: white !important;
  }

}

.image-background {
  background: rgb(32, 55, 132);

  h1 {
    text-align: center;
    font-family: 'century-gothic', sans-serif;
    font-size: 38px !important;
    background-color: white !important;
  }
}

.background-image-1 {
  background-image: url('./images/background.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.standard-help-page {
  max-width: 750px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 15px;
  // border-radius: 12px;
  // box-shadow: 0 3px 5px 2px rgba(0, 0, 0, .5);

  h1 {
    background-color: #203884 !important;
    font-size: 16px;
    // color: #203884 !important;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    color: #203884;
    font-size: 20px;
  }
  p {
    color: #203884;
    font-size: 16px;
  }
}

.standard-guidance-page {
  max-width: 750px;
  margin: auto;
  margin-left: 10px;
  margin-right: 10px;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 15px;
  // border-radius: 12px;
  // box-shadow: 0 3px 5px 2px rgba(0, 0, 0, .5);
  h1 {
    background-color: #203884 !important;
    // color: #203884 !important;
  }

  h3 {
    color: #203884;
  }
  p {
    color: #203884;
  }
}


.header-gradient {

  &.standard-page {
    max-width: 550px;
    margin: auto;
    // border-radius: 12px;
    // box-shadow: 0 3px 5px 2px rgba(0, 0, 0, .5);
  }

  &.more-margin {
    padding-left: 30px;
    padding-right: 30px;
  }

  h1 {
    text-align: center;
    font-size: 32px;
    background: black;//-webkit-linear-gradient(180deg, rgb(255, 255, 255), rgb(118, 169, 245), rgb(255, 255, 255), rgb(117, 164, 235), rgb(255, 255, 255));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.paper {
  position: 'absolute';
  width: 400px;
  background-color:white;
  border: '2px solid #000';
  box-shadow: black;
  padding: 20px;
  margin: auto;
  margin-top: 10%;
  text-align: center;
  border-radius: 10px;
  
  &.form {
    color: #203884;
    p {
      font-size: 12px;
      padding-left: 2px;
      padding-right: 2px;
    }
    a {
      font-size: 12px;
    }

    @media screen and (max-width: 550px) , screen and (max-height: 600px) {
      margin-top: 20px;
      h1 {
        font-size: 18px;
      }
    }
  }

  //color: #A5843A

  width: 70%;
  max-width: 400px;

  .MuiButton-root {
    text-align: center !important;

    &:hover {
    background-color: rgb(118, 169, 245);
  }
  }
}

.modal {
  .container {
    display: flex;
    flex-wrap: wrap;
  }

  .textField {
    margin-left: 20px;
    margin-right: 20px;
    width: 200px;
  }

  .modal-button {
    margin: auto;
    margin-top: 40px;
    font-family: Roboto;

    @media screen and (max-height: 600px) {
      margin-top: 10px;
    }

    .MuiButton-label {
      font-family: century-gothic, sans-serif !important;
      font-weight: bold;
      font-size: 22px;
    }
  }
}

.gridbox {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
}

.centerme {
  margin: auto;
  max-width: 400px;
  text-align: center;
}

.design-brand-footer {
  font-size: 6pt;
  margin-bottom: 10px;
  color: gray;
  padding: 4px;

  &:hover {
    font-size: 6.1pt;
    font-weight: bold;
    cursor: pointer;
  }
}

// Animation Loader

.lds-spinner {
  color: official;
  display: inline-block;
  position: fixed;
  bottom: 20px;
  z-index: 3;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: rgb(27, 114, 172);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}