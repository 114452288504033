
.card-field-over {
    position: relative;

    //border: 2px black solid;
    margin: 20px auto;
    margin-bottom: 60px;

    .position-title {
        color: white;
        font-weight: bold;
        font-size: 12pt;
        text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000; 
    }

    &.gamemode-1 {
        width: 150px;
    }

    &.gamemode-5 {
        
        height: 680px;
        width: 470px;

        .field-card {

            position: absolute;
            width: 140px;

            &.work      {top: 240px; left: 000px};
            &.home      {top: 240px; left: 320px};
            &.outside   {top: 000px; left: 160px};
            &.you       {top: 460px; left: 160px};
            &.outcome   {top: 240px; left: 160px};

            &:hover {
                z-index: 10;
            }
        }
    }

    &.gamemode-7 {

        height: 650px;
        width: 620px;

        .field-card {
            position: absolute;
            width: 140px;
            
            &.answer    {top: 420px; left: 240px};
            
            &.months    {top: 280px; left: 160px};
            &.outside   {top: 280px; left: 320px};

            &.now       {top: 140px; left: 80px};
            &.you       {top: 140px; left: 400px};

            &.past      {top: 000px; left: 000px};
            &.outcome   {top: 000px; left: 480px};

            &:hover {
                z-index: 10;
            }
        }
    }

    &.bigger {
        width: 350px !important;
    }
}

.card-field-playing {
    margin: 20px auto;

    background-image: url('../images/pattern.png');
    -webkit-background-size: repeat;
    -moz-background-size: repeat;
    -o-background-size: repeat;
    background-size: 30px 30px;
    background-position: center;

    min-height: 108px;
    padding: 8px;

    &.gamemode-1 {width: 170px};
    &.gamemode-5 {width: 400px};
    &.gamemode-7 {width: 560px};

    border: 3px rgba(255,255,255,0.5) solid;
    border-radius: 10px;
}

.field-card-container {
    display: inline-block;
    margin: 4px;
    z-index: 0;   
}

@media only screen and (max-width: 700px) {
    .card-field-playing {
        
        &.gamemode-5 {max-width: 80vw};
        &.gamemode-7 {max-width: 80vw};
    }
    
    .card-field-over {

        margin: 20px auto;
        margin-bottom: 60px;

        &.gamemode-1 {
            width: 100px;
        }

        &.gamemode-5 {
        
            height: 500px;
            width: 340px;
    
            .field-card {
                width: 100px;
    
                &.work      {top: 180px; left: 000px};
                &.home      {top: 180px; left: 230px};
                &.outside   {top: 000px; left: 115px};
                &.you       {top: 340px; left: 115px};
                &.outcome   {top: 180px; left: 115px};
            }
        }

        &.gamemode-7 {

            height: 500px;
            width: 320px;
    
            .field-card {
                width: 100px;
                          
                &.answer    {top: 330px; left: 105px};
                &.months    {top: 220px; left: 70px};
                &.outside   {top: 220px; left: 150px};
                &.now       {top: 110px; left: 35px};
                &.you       {top: 110px; left: 175px};
                &.past      {top: 000px; left: 000px};
                &.outcome   {top: 000px; left: 210px};
            }
        }
    }   
} 

@media only screen and (max-width: 350px) {
    .card-field-over {
        margin-bottom: 60px;
        
        &.gamemode-5 {
            width: 300px;
            .field-card {

                &.work      {top: 170px; left: 000px};
                &.home      {top: 170px; left: 200px};
                &.outside   {top: 000px; left: 100px};
                &.you       {top: 320px; left: 100px};
                &.outcome   {top: 170px; left: 100px};
            }
        }
    }
}