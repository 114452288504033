.journal-entry {

    min-height: 100vh;

    display: flex;
    flex-direction: column;
    text-align: center;
    .container {     
        max-width: 800px;
        margin: auto;
    }
    
    p {color: white}

    form {
        position: relative;
        text-align: left;
        padding: 20px;
        margin: 20px;
        border-radius: 5px;
        background: white;

        h2, p {color: rgb(24, 24, 24)};
        p { margin-bottom: 40px};
    };

    .input-style {margin-bottom: 16px};
}