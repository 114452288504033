
// browse page scss

// browse card images
.card-browse-img {
    height: 230px;
    width: 166px;
    // padding: 3px;
    margin: 6px;
    border-radius: 10px;
    // background: linear-gradient(90deg, white, silver);

    -webkit-box-shadow: 0px 0px 51px -22px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 51px -22px rgba(0,0,0,1);
    box-shadow: 0px 0px 51px -22px rgba(0,0,0,1);
    
    img {
        border-radius: 10px;
        height: 230px;
        opacity: 0.9;

        &:hover {
            z-index: 10;
            opacity: 1;
            cursor: pointer;
        };
    }; 
}

// category containers for browse page
.browse-category-container {
    display: inline-block;
    background: rgb(131, 190, 230);
    padding: 15px 15px;
    max-width: 1360px;
    margin: 14px;
    border-radius: 10px;

    // &.Life {background-image: url("/images/life-bg.jpg")};
    // &.Action {background-image: url("/images/action-bg.jpg")};
    // &.Emotion {background-image: url("/images/emotion-bg.jpg")};
    // &.Mind {background-image: url("/images/mind-bg.jpg")};
    // &.Practical {background-image: url("/images/practical-bg.jpg")};

    &.Life {background-color: #A5843A;};
    &.Action {background-color: rgb(175, 191, 196);};
    &.Emotion {background-color: rgb(175, 191, 196);};
    &.Mind {background-color: rgb(175, 191, 196);};
    &.Practical {background-color: rgb(175, 191, 196);};

    background-image: no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    h2 {
        margin: 0px 0px 10px 0px;
        text-align: left;
        color: #203884;
        text-align: center;
    }
}

// media queries for mobile screens
@media only screen and (max-width: 900px) {

    .card-browse-img {
        height: 200px;
        width: 144px;

        img {height: 200px};
    }
}

@media only screen and (max-width: 385px) {

    .card-browse-img {
        height: 180px;
        width: 130px;

        img {height: 180px};
    }
}

@media only screen and (max-width: 360px) {

    .card-browse-img {
        height: 140px;
        width: 103px;

        img {height: 140px};
    }
}

// animations

.browse-grow { transition: all .2s ease-in-out; }
.browse-grow:hover{ transform: scale(1.05)}