
// readings card container
.Readings {
    display: flex;
    flex-direction: column;

    min-height: 100vh;

    text-align: center;
    align-items: center;

    font-family: 'century-gothic', sans-serif;
    font-size: calc(10px + 2vmin);

    color: white;

    form {
        position: relative;
        text-align: center;
        padding: 20px;
        margin: 10px;
        border-radius: 5px;
        background: rgba(15, 52, 109, 0.05);

        h2, p {color: rgb(24, 24, 24)};
        p { margin: 20px 0px};
    };

    .input-style {margin-bottom: 16px};
  }

.card-container {
    display: inline-block;
    margin: 2px;
    z-index: 0;   
}

.deck-container {
    max-width: 1000px;
    &.wide {max-width: 1500px};
}

// readings card images
.card-img {
    height: 95px;
    width: 72px;

    -webkit-box-shadow: 0px 0px 51px -22px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 51px -22px rgba(0,0,0,1);
    box-shadow: 0px 0px 51px -22px rgba(0,0,0,1);

    img {
        border-radius: 6px;
        height: 100px;

        &:hover{
            cursor: pointer; 
         }
    }

    &.selected {
        height: 200px;
        width: 140px;
        img {height: 200px};
    }

    &.bigger {
        height: 300px;
        width: 216px;
        img {height: 300px};
        margin: auto;
        text-align: center;
    align-items: center;
    }
}

//animations
.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.3)}

.selected-grow { transition: all .2s ease-in-out; }
.selected-grow:hover{ transform: scale(1.05)}

@media only screen and (max-width: 700px) {
    .card-img {
        &.selected {
            height: 150px;
            width: 100px;
            img {height: 150px};
        }

        &.bigger {
            height: 300px;
            width: 216px;
            img {height: 300px};
            margin: auto;
        }
    }
} 

@media only screen and (max-width: 350px) {
    .card-img {
        &.selected {
            height: 130px;
            width: 85px;
            img {height: 130px};
        }

        &.bigger {
            height: 300px;
            width: 200px;
            img {height: 300px};
            margin: auto;
        }
    }
}